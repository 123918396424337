import VueRouter from "vue-router";
import CoursesContent from "./components/courses/Content";
import CourseContent from "./components/course/Content";
import Lesson from "./components/Lesson.vue";
import Test from "./components/Test.vue";
import Login from "./components/auth/Login";
import DocumentView from "./components/documents/_DocumentView";
import PersonnelContent from "@/components/documents/PersonnelContent";
import Training from "@/components/training/Training";
import Trainings from "@/components/trainingNew/Training";
import {default as TestNew} from "@/components/trainingNew/components/Tests";
import Section from "@/components/trainingNew/components/Section";
import SubSections from "@/components/trainingNew/components/SubSections";
import Settings from "@/components/personal/Settings";
import Registration from "@/components/auth/Registration";
import ForgotPassword from "@/components/auth/ForgotPassword";
import AgreeDocumentView from "@/components/documents/AgreeDocumentView";
import TheDocument from "@/components/documents/TheDocument";
import ThePersonnelDocument from "@/components/documents/ThePersonnelDocument";
import ExternalTraining from "@/components/documents/external/ExternalTraining";
import TheCertificateRequest from "@/components/certificates/TheCertificateRequest";
import RegistrationVnot from "@/components/auth/RegistrationVnot";
import RegistrationVnotConfirm from "@/components/auth/RegistrationVnotConfirm";

const routes = [
  {
    path: '/',
    component: CoursesContent,
    name: 'courses',
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      layout: 'TheAuth',
      withoutRedirect: true,
    }
  },
  // {
  //   path: '/worker-login',
  //   component: WorkerLogin,
  //   name: 'worker-login',
  //   meta: {
  //     layout: 'TheAuth'
  //   }
  // },
  {
    path: '/registration',
    component: Registration,
    name: 'registration',
    meta: {
      layout: 'TheAuth'
    }
  },
  {
    path: '/vnot/registration',
    component: RegistrationVnot,
    name: 'registrationVnot',
    meta: {
      layout: 'TheAuth',
      withoutRedirect: true,
    }
  },
  {
    path: '/vnot/registration/confirm',
    component: RegistrationVnotConfirm,
    name: 'registrationVnotConfirm',
    meta: {
      layout: 'TheAuth',
      withoutRedirect: true,
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      layout: 'TheAuth'
    }
  },
  {
    path: '/course/:id/:type?',
    component: CourseContent,
    name: 'course',
    meta: {
      layout: 'TheCourse'
    }
  },
  {
    path: '/lesson/:needId(\\d+)/:courseId(\\d+)/:id(\\d+)/:type(exttraining|ext)?',
    component: Lesson,
    name: 'lesson'
  },
  {
    path: '/test/:needId(\\d+)/:courseId(\\d+)/:id(\\d+)/:type(exttraining|ext)?',
    component: Test,
    name: 'test'
  },
  {
    path: '/training-test/:id?/:paperId?',
    component: Test,
    name: 'training-test',
  },
  {
    path: '/documents/:id',
    component: TheDocument,
    name: 'document',
  },
  {
    path: '/documents',
    component: PersonnelContent,
    name: 'documents',
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/external_education',
    component: ExternalTraining,
    name: 'external_education',
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/personnel-documents/:id',
    component: ThePersonnelDocument,
    name: 'personnel-document',
  },
  {
    path: '/personnel-documents',
    component: PersonnelContent,
    name: 'personnel_documents',
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/document/:link/:type',
    component: DocumentView,
    name: 'document-view'
  },
  {
    path: '/training',
    component: Training,
    name: 'training',
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/personal',
    component: Settings,
    name: 'personal',
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/personal-certificate',
    component: TheCertificateRequest,
    name: 'certificate',
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/agree-document/:link',
    component: AgreeDocumentView,
    name: 'agree-document'
  },
  {
    path: '*',
    name: 'undefined',
    component: CoursesContent,
    meta: {
      layout: 'TheProfile'
    }
  },
  {
    path: '/trainings',
    name: 'trainings',
    component: Trainings,
    meta: {
      layout: 'TheTraining'
    },
  },
  {
    path: '/trainings/section/:id',
    name: 'section',
    component: Section,
    meta: {
      layout: 'TheTraining'
    },
    children: [
      {
        path: '',
        name: 'subsection',
        component: SubSections,
        meta: {
          layout: 'TheTraining'
        },
      },
      {
        path: 'subsection/:subsectionid/tests/:testid',
        name: 'newtest',
        component: TestNew,
        meta: {
          layout: 'TheTraining'
        },
      },
    ],
  }
];

export default new VueRouter({
  mode: 'history',
  routes
})